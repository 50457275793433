import { InjectionToken, Provider } from '@angular/core';

export const GOOGLE_OPTIONS = new InjectionToken('GOOGLE_OPTIONS');

export interface GoogleModuleOptions {
  headerMetaOptionsProvider?: Provider;
  config?: {
    GA_TRACKING_ID: string;
    AW_CONVERSION_ID: string;
  };
}
