import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TextareaModule } from 'primeng/textarea';
import { InputLabelComponent } from './input-label.component';
import { InputLabelTestComponent } from './input-label.component-test';

const declarations = [InputLabelComponent, InputLabelTestComponent];

@NgModule({
  imports: [CommonModule, NgxBootstrapFormValidationModule, FormsModule, ReactiveFormsModule, InputTextModule, InputTextareaModule, TextareaModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class NgxBootstrapInputModule {}
