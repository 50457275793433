import { Inject, Injectable } from '@angular/core';
import { GOOGLE_OPTIONS, GoogleModuleOptions } from './google.options';

@Injectable()
export class GoogleService {
  public GA_TRACKING_ID: string = '';
  public AW_CONVERSION_ID: string = '';
  private _gtag = null;

  constructor(@Inject(GOOGLE_OPTIONS) config: GoogleModuleOptions['config'] = null) {
    this.GA_TRACKING_ID = config && config.GA_TRACKING_ID;
    this.AW_CONVERSION_ID = config && config.AW_CONVERSION_ID;

    this.initGtag(window, document, `https://www.googletagmanager.com/gtag/js?id=${this.GA_TRACKING_ID}`, 'gtag');
    this.gtag('js', new Date());
    this.gtag('config', this.GA_TRACKING_ID);
    this.gtag('config', this.AW_CONVERSION_ID);
  }

  gtag(...args) {
    this._gtag(...args);
  }

  initGtag(win: Window, doc: Document, url: string, tagName: string, scriptDom?: HTMLScriptElement, domToAppend?: HTMLScriptElement) {
    win.dataLayer = win.dataLayer || [];
    this._gtag = win.gtag = function (...args) {
      window.dataLayer.push(arguments);
    };
    scriptDom = doc.createElement('script');
    domToAppend = doc.getElementsByTagName('script')[0];
    scriptDom.async = true;
    scriptDom.src = url;
    domToAppend.parentNode.insertBefore(scriptDom, domToAppend);
  }
}
