import { CommonModule, NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AppwriteAngularModule, AuthConfig, SENTRY_PROVIDER } from '@appwrite/angular';
import { FaConfig, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModulesAppwriteModule } from '@mm-mono/modules/appwrite/client';
import { BuildDetailsHttpService, fetchBuildDetails, NgxAppVersionCheckerModule } from '@mm-mono/ngx/app-version-checker';
import { NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { NgxBootstrapInputModule } from '@mm-mono/ngx/bootstrap-input';
import { NgxDialogsModule } from '@mm-mono/ngx/dialogs';
import { NgxGoogleModule } from '@mm-mono/ngx/google';
import { MetaModule } from '@mm-mono/ngx/meta';
import * as Sentry from '@sentry/angular';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { PixelModule } from 'ngx-multi-pixel';
import { MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appState } from './app.states';
import { MmAuthConfig } from './config/mm-auth.config';
import { ErrorsComponent } from './errors/errors.components';
import { AppErrorHandler } from './util/app.logger';
import { FooterComponent } from './views/_base/footer/footer.component';
import { GreenBarComponent } from './views/_base/header/green-bar.component';
import { HeaderComponent } from './views/_base/header/header.component';
import { OrangeBarComponent } from './views/_base/header/orange-bar.component';
import { MainEntryComponent } from './views/_base/main-entry/main-entry.component';
import { AuthModule } from './views/public/auth/auth.module';
import { CarouselComponent } from './views/public/home/carousel.component';
import { HomeComponent } from './views/public/home/home.component';
import { MapComponent } from './views/public/home/map.component';
import { NewsletterSignupComponent } from './views/public/home/newsletter-signup.component';
import { ProductFeatureComponent } from './views/public/products/components/product-feature.component';

const componentParsers: Array<HookParserEntry> = [{ component: ProductFeatureComponent }];

@NgModule({
  declarations: [ErrorsComponent, MainEntryComponent, AppComponent, HomeComponent, CarouselComponent, MapComponent, NewsletterSignupComponent],
  bootstrap: [MainEntryComponent],
  imports: [
    GoogleMapsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    FontAwesomeModule,
    NgxDialogsModule,
    NgxAppVersionCheckerModule.forRoot(),
    NgxBootstrapFormValidationModule.forRoot(),
    PixelModule.forRoot({ enabled: true, pixelId: ['1095601967204935'] }),
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers,
    }),
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: environment.production ? 'minnich-mfg.com' : 'localhost',
      },
      palette: {
        popup: {
          background: '#ffffff',
        },
        button: {
          background: '#036d47',
        },
      },
      theme: 'edgeless',
      type: 'opt-out',
    }),
    RouterModule.forRoot([appState], {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
    }),
    MetaModule.forRoot({
      config: { defaultAppTitle: 'Minnich Manufacturing' },
    }),
    NgxGoogleModule.forRoot({
      config: {
        AW_CONVERSION_ID: 'AW-995256393',
        GA_TRACKING_ID: 'G-NMZ7JMZRYS',
      },
    }),
    ModulesAppwriteModule.forRoot(),
    AppwriteAngularModule.forRoot({
      config: {
        endpointUrl: environment.appwriteEndpoint,
        projectId: environment.appwriteProjectId,
      },
    }),
    NgxBootstrapInputModule,
    MessageModule,
    ToastModule,
    ConfirmDialogModule,
    CarouselModule,
    MenuModule,
    MegaMenuModule,
    NgOptimizedImage,
    HeaderComponent,
    OrangeBarComponent,
    GreenBarComponent,
    FooterComponent,
    ProductFeatureComponent,
    InputTextModule,
  ],
  providers: [
    MessageService,
    DialogService,
    { provide: SENTRY_PROVIDER, useFactory: () => Sentry },
    {
      provide: AuthConfig,
      useClass: MmAuthConfig,
      deps: [Router, MessageService],
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: fetchBuildDetails,
      deps: [BuildDetailsHttpService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class AppModule {
  constructor(private faConfig: FaConfig) {
    faConfig.fixedWidth = true;
  }
}
