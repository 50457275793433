import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { META_OPTIONS } from './header-meta.options';
import { HeaderMetaModuleOptions } from './meta.module';

@Injectable()
export class HeaderMetaService {
  public preRenderStatusCode: number = 200;
  public defaultAppTitle: string = '';

  constructor(@Inject(META_OPTIONS) config: HeaderMetaModuleOptions['config'] = null, private meta: Meta, private title: Title) {
    this.defaultAppTitle = config && config.defaultAppTitle;
  }

  setStatusCode(code: number) {
    this.meta.updateTag({
      name: 'prerender-status-code',
      content: code.toString(),
    });
  }

  setMeta(data: { title: string; description?: string; image?: string; url?: string }) {
    this.setTitle(data.title);
    this.setDescription(data.description || '');
    this.setImage(data.image || '');
    this.setUrl(data.url || '');
  }

  setTitle(title: string) {
    this.title.setTitle(`${title} - ${this.defaultAppTitle}`);
    this.meta.updateTag({
      property: 'og:title',
      content: `${title} - ${this.defaultAppTitle}`,
    });
  }

  setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
  }

  setUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  setImage(url: string) {
    this.meta.updateTag({ property: 'og:image', content: url });
  }
}
