import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { mustMatchError } from '@appwrite/angular';
import { NgxBootstrapInputModule } from '@mm-mono/ngx/bootstrap-input';
import { ModulesAppwriteModule } from '@mm-mono/modules/appwrite/client';
import { CUSTOM_ERROR_MESSAGES, NgxBootstrapFormValidationModule } from '@mm-mono/ngx/bootstrap-form-validation';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { LogoComponent } from '../../_base/logo/logo.component';
import { AuthForgotComponent } from './auth-forgot.component';
import { AuthLoginComponent } from './auth-login.component';
import { AuthRegisterComponent } from './auth-register.component';
import { AuthResetComponent } from './auth-reset.component';
import { AuthVerifyComponent } from './auth-verify.component';
import { AUTH_ROUTES } from './auth.states';

@NgModule({
  imports: [
    RouterModule.forChild(AUTH_ROUTES),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapFormValidationModule,
    ModulesAppwriteModule,
    NgxBootstrapInputModule,
    LogoComponent,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
  ],
  declarations: [AuthLoginComponent, AuthRegisterComponent, AuthForgotComponent, AuthResetComponent, AuthVerifyComponent],
  providers: [
    {
      provide: CUSTOM_ERROR_MESSAGES,
      useValue: mustMatchError,
      multi: true,
    },
  ],
  exports: [],
})
export class AuthModule {}
