import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { defer, EMPTY, iif } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-newsletter-signup',
  template: `
    <div class="grid md:grid-cols-2">
      <appwrite-image
        [src]="'646f5e4f9f5765cccc05'"
        [width]="500"
        class="aspect-[16/9] rounded-2xl rounded-b-none md:aspect-[9/16] md:rounded-r-none"
        objectFit="cover"
        style=""
        imgClasses="w-full h-full"
      ></appwrite-image>
      <div class="flex flex-col justify-between text-center">
        <div class="flex-grow-1"></div>
        <div class="p-8">
          <h6>Join the Minnich family!</h6>
          <p class="mb-2 leading-8">Stay connected with the latest updates, exclusive offers, and helpful tips from Minnich Manufacturing.</p>
          <form [formGroup]="form" autocomplete="off">
            <div class="mb-3">
              <input formControlName="email" id="email" placeholder="Email Address" pInputText type="email" />
            </div>
            <div class="text-center">
              <button
                (click)="submit()"
                [disabled]="!form.valid"
                class=" bg-accent-600 hover:bg-accent-500 focus-visible:outline-accent-600   justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                id="button-addon2"
                type="button"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <a (click)="negative()" class="text-dark flex-grow-0 cursor-pointer py-6 text-center underline">no thanks</a>
      </div>
    </div>
  `,
})
export class NewsletterSignupComponent implements OnInit {
  public form: UntypedFormGroup;
  public result: Promise<void>;
  @Input() message: string;
  @Input() title: string;
  @Input() positiveButtonText;
  @Input() negativeButtonText;
  @Input() neutralButtonText;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private dialogsService: NgxDialogsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public positive() {
    localStorage.setItem('newsletterSignupResult', String(true));
    this.ref.close();
  }

  public negative() {
    localStorage.setItem('newsletterSignupResult', String(false));
    this.ref.close();
  }

  public neutral() {
    this.ref.close();
  }

  public submit() {
    if (this.form.status === 'VALID') {
      const params = new HttpParams()
        .set('u', 'a7b6d5bdddc6420b5eeb4b845')
        .set('id', 'cf84fe0061')
        .set('ht', '81c95eb1b54c5597cff879ee94cd30de0bfedc79:MTY4NTExMjM0Ny44NTk3')
        .set('mc_signupsource', 'hosted')
        .set('MERGE0', this.form.get('email').value);

      const mailChimpUrl = `https://minnich-mfg.us1.list-manage.com/subscribe/post-json?` + params.toString();

      this.http
        .jsonp<MailChimpResponse>(mailChimpUrl, 'c')
        .pipe(
          catchError((error) => this.dialogsService.alert({ message: error.msg, header: 'Error' }).pipe(mergeMap(() => EMPTY))),
          mergeMap((d) =>
            iif(
              () => d?.result !== 'error',
              defer(() => this.dialogsService.alert({ message: d.msg })),
              defer(() => this.dialogsService.alert({ message: d.msg, header: 'Error' }).pipe(mergeMap(() => EMPTY)))
            )
          )
        )
        .subscribe((d) => this.negative());
    }
  }
}
