import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxDialogsService } from '@mm-mono/ngx/dialogs';
import { GoogleService } from '@mm-mono/ngx/google';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { PixelService } from 'ngx-multi-pixel';
import { PrimeNGConfig } from 'primeng/api';
import { definePreset } from 'primeng/themes';
import { Aura } from 'primeng/themes/aura';
import { NewsletterSignupComponent } from './views/public/home/newsletter-signup.component';

const MyPreset = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        highlight: {
          background: '{accent.300}',
          color: '#ffffff',
          focusBackground: '{accent.200}',
          focusColor: '#ffffff',
        },
      },
    },
    primary: {
      50: '{accent.50}',
      100: '{accent.100}',
      200: '{accent.200}',
      300: '{accent.300}',
      400: '{accent.400}',
      500: '{accent.500}',
      600: '{accent.600}',
      700: '{accent.700}',
      800: '{accent.800}',
      900: '{accent.900}',
    },
    formField: {
      paddingY: '0.5rem',
    },
  },
} as typeof Aura);

@Component({
  selector: 'app-root',
  template: `
    <header></header>
    <router-outlet></router-outlet>
    <footer class="bg-neutral-900 py-5"></footer>
  `,
  styles: [],
})
export class AppComponent implements OnInit {
  constructor(
    private config: PrimeNGConfig,
    private router: Router,
    private googleService: GoogleService,
    private consentService: NgcCookieConsentService,
    private dialogsService: NgxDialogsService,
    private pixel: PixelService
  ) {
    this.config.theme.set({
      preset: MyPreset,
      options: {
        prefix: '',
        cssLayer: {
          name: 'primeng',
          order: 'tailwind-base, primeng, tailwind-utilities',
        },

        darkModeSelector: '.mm-dark',
      },
    });
    this.pixel.initialize();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleService.gtag('config', this.googleService.GA_TRACKING_ID, {
          page_path: event.urlAfterRedirects,
        });
        this.googleService.gtag('config', this.googleService.AW_CONVERSION_ID, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (localStorage.getItem('newsletterSignupResult') === 'false') return;
      this.dialogsService
        .component(NewsletterSignupComponent, {
          width: '50vw',
          modal: true,
          showHeader: false,
          maskStyleClass: 'bg-[rgba(0,0,0,0.4)]',
          styleClass: 'rounded-2xl',
          contentStyle: { margin: '0', padding: '0', 'border-radius': '1rem' },
          breakpoints: {
            '1024px': '80vw',
            '1280px': '70vw',
          },
        })
        .subscribe();
    }, 1000);
  }
}
