import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { GOOGLE_OPTIONS, GoogleModuleOptions } from './google.options';
import { GoogleService } from './google.service';

declare global {
  interface Window {
    gtag?: (...args: any) => void;
    dataLayer?: any;
  }
}

export * from './google.options';
export * from './google.service';

@NgModule()
export class NgxGoogleModule {
  constructor(@Optional() @SkipSelf() parentModule: NgxGoogleModule) {
    if (parentModule) {
      throw new Error("GoogleModule is already loaded. It should only be imported in your application's main module.");
    }
  }

  static forRoot(options: GoogleModuleOptions): ModuleWithProviders<NgxGoogleModule> {
    return {
      ngModule: NgxGoogleModule,
      providers: [
        options.headerMetaOptionsProvider || {
          provide: GOOGLE_OPTIONS,
          useValue: options.config,
        },
        GoogleService,
      ],
    };
  }
}
